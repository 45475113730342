@tailwind base;
@tailwind components;
@tailwind utilities;



.swiper-button-next:after,
.swiper-rtl .swiper-button-prev::after {
  content: "next";
  color: gray;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: gray;
  display: none;
  line-height: 100;
}

.swiper-pagination-bullet-active {
  background-color: gray;
}

.container {
  width: 100%;
  height: 100%;
  --bg: linear-gradient(
      45deg,
      rgb(0, 0, 0) 25%,
      transparent 25%,
      transparent 75%,
      rgb(0, 0, 0) 75%,
      rgb(0, 0, 0)
    ),
    linear-gradient(
      45deg,
      rgb(0, 0, 0) 25%,
      #0e6901 25%,
      #0e6901 75%,
      rgb(0, 0, 0) 75%,
      rgb(0, 0, 0)
    );
  --bgsize: 60px 60px;
  --bgposition: 0 0, 30px 30px;
  background-color: #0e6901;
  background-image: var(--bg);
  background-size: var(--bgsize);
  background-position: var(--bgposition);
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  overflow: hidden;
  box-shadow: inset 0px 0px 500px rgb(15, 15, 15);
}

.container::before {
  content: "";
  width: 100%;
  height: 150%;
  box-shadow: 0px -100px 500px black, inset 0px 100px 500px rgb(15, 15, 15);
  position: absolute;
  top: 55%;
  background-image: var(--bg);
  background-size: var(--bgsize);
  background-position: var(--bgposition);
  transform-origin: top;
  transform: rotateX(80deg);
}



